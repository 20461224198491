const header = document.getElementsByTagName('header');
const navbar = document.getElementById('navbar')!;

function setBackground() {
    const item = header?.item(0);
    if (item && item.getBoundingClientRect().top < (-navbar.getBoundingClientRect().height)) {
        navbar!.classList.add('bg-primary');
    } else {
        navbar!.classList.remove('bg-primary');
    }
}

document.addEventListener('scroll', () => {
    setBackground();
});
setBackground();